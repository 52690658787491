import PropTypes from 'prop-types'
import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { withTheme } from 'emotion-theming'

import Boilerplate from '../../images/pods/Boilerplate'
import EpisodeCard from './EpisodeCard'

import {
  Container as Containr,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  flex: 1;
  position: relative;
  margin-bottom: 5rem;
  ${mediaQuery.mobile} {
    margin-bottom: 1rem;
  }
`
const GreyOutlineBackground = styled.div`
  position: absolute;
  width: calc((100vw / 2) + (37.5rem / 2 - 5rem));
  height: calc(100% + 2.5rem);
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  ${mediaQuery.tabletLandscape} {
    width: calc(95vw - 5rem);
  }
  ${mediaQuery.tablet} {
    width: calc(95vw - 5rem);
  }
  ${mediaQuery.mobile} {
    width: 90vw;
    height: 100%;
  }
`
const CenterContainer = styled(Center)`
  margin-top: 4.375rem;
  position: relative;
  ${mediaQuery.tablet} {
    margin-top: 3.25rem;
  }
  ${mediaQuery.mobile} {
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;
  }
`
const Title = styled.div`
  ${({ theme }) => theme.fonts.headline2};
  color: ${({ theme }) => theme.colors.yellow};
  margin-bottom: 1rem;
  z-index: 1;
  ${mediaQuery.tablet} {
    ${({ theme }) => theme.fonts.headline2_tablet};
    padding-left: 1rem;
  }
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline2_mobile};
    padding-left: 1rem;
  }
`
const EpisodeCardStyled = styled(EpisodeCard)`
  position: absolute;
  top: -1rem;
  right: 0;
  width: 10rem;
  height: 16.875rem;
  background-color: ${({ theme }) => theme.colors.blackLighter};
  z-index: 1;
  ${mediaQuery.mobile} {
    position: relative;
    top: unset;
    right: unset;
    margin: auto auto 1.7rem;
    width: 65vw;
    height: 100vw;
  }
`
const EpisodeImageStyled = styled(Boilerplate)`
  height: 50%;
  width: 100%;
`
const DescriptionContainer = styled.div`
  display: flex;
`
const DescriptionTextContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-right: 10rem;
  ${mediaQuery.mobile} {
    margin: 0 auto;
    padding: 0 1.5rem;
  }
`
const DescriptionBlackBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(((100vw - 37.5rem) / 2) + 37.5rem);
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.black};
  ${mediaQuery.tabletLandscape} {
    top: 0;
    height: calc(100%);
    width: 95vw;
  }
  ${mediaQuery.tablet} {
    top: 0;
    height: calc(100%);
    width: 95vw;
  }
  ${mediaQuery.mobile} {
    top: -12rem;
    height: calc(100% + 12rem);
    width: 100vw;
  }
`
const DescriptionTitleText = styled.div`
  ${({ theme }) => theme.fonts.headline3};
  margin: 2rem auto 1rem 2rem;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  ${mediaQuery.mobile} {
    margin: 0 0 0.5rem;
  }
`
const DescriptionText = styled.div`
  ${({ theme }) => theme.fonts.mainBodyText};
  margin: 0 2rem 2rem;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};

    margin: 0 0 1.5rem 0;
  }
`
const CodeToLearn = ({ theme, text }) => {
  const { title, body, action, tags } = useStaticQuery(graphql`
    query EpisodeQuery {
      episodes: allAirtable(
        filter: { table: { eq: "episodes" }, data: { active: { eq: true } } }
        sort: { fields: data___order, order: ASC }
      ) {
        nodes {
          data {
            title
            body
            action
            tags
          }
          recordId
        }
      }
    }
  `).episodes.nodes[0].data

  return (
    <Container id="podcast">
      <Spacer></Spacer>
      <GreyOutlineBackground />
      <CenterContainer>
        <Title>{text.title}</Title>
        <EpisodeCardStyled
          text={{
            title,
            body,
            action,
            tags,
          }}
        >
          <EpisodeImageStyled></EpisodeImageStyled>
        </EpisodeCardStyled>
        <DescriptionContainer>
          <DescriptionTextContainer>
            <DescriptionTitleText>{text.descriptionTitle}</DescriptionTitleText>
            <DescriptionText>{text.descriptionBody}</DescriptionText>
            <DescriptionBlackBackground></DescriptionBlackBackground>
          </DescriptionTextContainer>
        </DescriptionContainer>
      </CenterContainer>
      <Spacer></Spacer>
    </Container>
  )
}

CodeToLearn.propTypes = {
  theme: PropTypes.object,
}

CodeToLearn.defaultProps = {
  theme: {},
}

export default withTheme(CodeToLearn)
