import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import {
  Container as Containr,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  flex: 1;
  position: relative;
  margin-bottom: 1rem;
  ${mediaQuery.mobile} {
    margin-bottom: 0.75rem;
  }
`
const SpacerRedLine = styled.div`
  position: absolute;
  top: calc(3rem * 1.05 * 2 - 3rem * 1.05 * 0.5);
  left: 0;
  width: calc(((100vw - 37.5rem) / 2) + (37.5rem - 15rem));
  height: 2px;
  padding-right: 0.625rem;
  background-color: ${({ theme }) => theme.colors.red};
  ${mediaQuery.tabletLandscape} {
    width: calc(95vw - 15rem);
  }
  ${mediaQuery.tablet} {
    width: calc(95vw - 15rem);
  }
  ${mediaQuery.mobile} {
    top: calc(1.75rem * 1 * 2 - 1.75rem * 1 * 0.5);
    width: calc(100vw - 12rem);
  }
`
const CenterContainer = styled(Center)`
  justify-content: flex-end;
`
const Title = styled.div`
  ${({ theme }) => theme.fonts.headline2};
  color: ${({ theme }) => theme.colors.red};
  width: 14.0625rem;
  margin-bottom: 0.75rem;
  text-align: right;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline2_mobile};
    padding-right: 1rem;
  }
`
const Description = styled.div`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  color: ${({ theme }) => theme.colors.black};
  width: 29.0625rem;
  text-align: right;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};
    padding-right: 1rem;
    padding-left: 1rem;
  }
`
const Waitlist = ({ theme, text }) => {
  return (
    <Container id="community">
      <Spacer />
      <SpacerRedLine />
      <CenterContainer>
        <Title>{text.title}</Title>
        <Description>{text.body}</Description>
      </CenterContainer>
      <Spacer />
    </Container>
  )
}

Waitlist.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Waitlist)
