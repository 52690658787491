import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../v0/layouts/Layout'
import SEO from '../v0/seo/seo'
import Hero from '../v0/components/Hero'
import CodeToLearn from '../v0/components/CodeToLearn'
import Waitlist from '../v0/components/Waitlist'
import Email from '../v0/components/Email'
import Suggested from '../v0/components/Suggested'
import LatestTutorial from '../v0/components/LatestTutorial'
import Upcoming from '../v0/components/Upcoming'
import Calendar from '../v0/components/Calendar'
import Footer from '../v0/components/Footer'
import Modal from '../v0/components/Modal'

const IndexPage = () => {
  const [modal, setModal] = useState(false)
  const indexText = useStaticQuery(graphql`
    query IndexTextQuery {
      indexText: allAirtable(
        filter: { table: { eq: "siteText" }, data: { page: { eq: "index" } } }
      ) {
        nodes {
          data {
            section
            key
            text
          }
          recordId
        }
      }
    }
  `).indexText.nodes.reduce((obj, node) => {
    const { section, key, text } = node.data
    if (!obj[section]) {
      obj[section] = {}
    }
    obj[section][key] = text
    return { ...obj }
  }, {})

  function modalToggler(modal) {
    console.log('I am the modal toggler: ', modal)
    if (!modal) {
      setModal(true)
    } else {
      setModal(false)
    }
  }

  return (
    <Layout>
      <SEO title="DevWell — Coding Education that Does More." />
      <Modal
        modalState={modal}
        modalToggler={modalToggler}
        text={indexText.email}
      />
      <Hero
        text={indexText.hero}
        modalState={modal}
        modalToggler={modalToggler}
      />
      <CodeToLearn text={indexText.ctl} />
      <Waitlist text={indexText.waitlist} />
      <Email text={indexText.email} />
      <Suggested text={indexText.suggested} />
      <LatestTutorial text={indexText.latest} />
      <Upcoming text={indexText.upcoming} />
      <Calendar />
      <Footer text={indexText.footer} />
    </Layout>
  )
}

export default IndexPage
