import React, { useState } from 'react'
import styled from '@emotion/styled'

import { mediaQuery } from './styled'
import axios from 'axios'

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
`

const ModalHeader = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0.5rem;
  padding: 0;
`

const ModalSubText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  font-size: 0.8rem;
`

const ModalInner = styled.div`
  padding: 2rem 1rem 0.6rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  ${mediaQuery.mobile} {
  }
  background-color: ${({ theme }) => theme.colors.blackDarker};
`
const ModalInput = styled.input`
  width: 100%;
  height: 2rem !important;
  padding-left: 1rem;
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0.45rem;
  border: none;
  ::placeholder {
    color: inherit;
  }
  :last-of-type {
    margin-bottom: 1rem;
  }
  &:focus::placeholder {
    color: transparent;
  }
  ${mediaQuery.mobile} {
    height: 1.5rem;
    width: 100%;
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};
    margin-bottom: 1rem;
  }
  ${mediaQuery.helper350} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_helper350};
  }
`
const ModalCloser = styled.button`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  width: 60%;
  height: 2rem;
  position: absolute;
  top: -1rem;
  left: -2rem;
  ${mediaQuery.mobile} {
    top: -1rem;
    left: 1rem;
  }
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`
const FormSubmit = styled.button`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  width: 5rem;
  height: 2rem;
  ${mediaQuery.mobile} {
  }
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`

const validateEmail = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email.toLowerCase()))
}

//Breakup this function into smaller bits
const subscribeUsersEmail = (
  event,
  emailAddress,
  firstName,
  lastName,
  setSuccessMessage,
  text
) => {
  console.log('I am text: ', text)
  console.log(
    'I am process.env.GATSBY_SENDGRID_API_KEY: ',
    process.env.GATSBY_SENDGRID_API_KEY
  )
  event.preventDefault()
  if (validateEmail(emailAddress)) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + process.env.GATSBY_SENDGRID_API_KEY,
    }
    const data = {
      list_ids: [process.env.GATSBY_CINDERHOUSE_EMAIL_LIST_ID],
      contacts: [
        {
          first_name: `${firstName}`,
          last_name: `${lastName}`,
          email: `${emailAddress}`,
        },
      ],
    }

    axios
      .put('https://api.sendgrid.com/v3/marketing/contacts', data, {
        headers,
      })
      .then(response => {
        setSuccessMessage(text.successMessage)
      })
      .catch(err => {
        setSuccessMessage(text.errorMessage)
        setTimeout(() => {
          setSuccessMessage(false)
        }, 5200)
      })
  } else {
    setSuccessMessage(`"${emailAddress}" ${text.invalidMessage}`)
    return setTimeout(() => {
      setSuccessMessage(false)
    }, 5200)
  }
}
const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
`

//Pass in a function that swaps the useState hook in the parent container

const Modal = ({ modalState, modalToggler, text }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [successMessage, setSuccessMessage] = useState(false)

  return modalState ? (
    <ModalWrapper>
      <ModalInner>
        {!successMessage ? (
          <>
            <TextWrapper>
              <ModalHeader>Join DevWell</ModalHeader>
              <ModalSubText>
                To mobilize quickly, we're bringing all learners, job seekers,
                and volunteers into Slack. To get an invite, give us your
                details and we'll send you a signup link within 48 hours.
              </ModalSubText>
            </TextWrapper>
            <ModalForm
              onSubmit={event =>
                subscribeUsersEmail(
                  event,
                  emailAddress,
                  firstName,
                  lastName,
                  setSuccessMessage,
                  text
                )
              }
            >
              <ModalInput
                placeholder={'First Name'}
                type="text"
                onChange={event => setFirstName(event.target.value)}
              />
              <ModalInput
                placeholder={'Last Name'}
                type="text"
                onChange={event => setLastName(event.target.value)}
              />
              <ModalInput
                placeholder={'Email Address'}
                type="email"
                onChange={event => setEmailAddress(event.target.value)}
              />
              <FormSubmit>Submit</FormSubmit>
            </ModalForm>
          </>
        ) : (
          <TextWrapper>
            <ModalHeader>Success!</ModalHeader>
            <ModalSubText>
              Your invitation request has been received. Check your email and
              then come join us in Slack. If for some reason you're unable to
              receive the email, contact us at community@devwell.org.
            </ModalSubText>
          </TextWrapper>
        )}
        <ModalCloser onClick={() => modalToggler(modalState)}>
          Close
        </ModalCloser>
      </ModalInner>
    </ModalWrapper>
  ) : (
    <></>
  )
}

export default Modal
