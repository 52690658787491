import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import { mediaQuery } from './styled'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.5rem;
  ${mediaQuery.mobile} {
    margin-bottom: 1rem;
  }
`
const LeftTab = styled.div`
  background-color: ${({ theme, color }) => theme.colors[color]};
  height: 3rem;
  width: 0.75rem;
  margin: auto 0;
  ${mediaQuery.mobile} {
    height: 3rem;
    width: 0.5rem;
  }
`
const Left = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  height: 3rem;
  width: 1rem;
  margin: auto 0;
  ${mediaQuery.mobile} {
    height: 3rem;
    width: 0.5rem;
  }
`
const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 5rem;
  width: 3.5rem;
  background-color: ${({ theme }) => theme.colors.blackLighter};
  ${mediaQuery.mobile} {
    height: 4rem;
    width: 2.75rem;
  }
`
const DayContainer = styled.div`
  display: flex;
  width: 100%;
  height: 70%;
`
const Day = styled.div`
  ${({ theme }) => theme.fonts.calendarDay};
  margin: auto;
  color: ${({ theme }) => theme.colors.white};
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calendarDay_mobile};
  }
`
const MonthContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  background-color: ${({ theme, color }) => theme.colors[color]};
`
const Month = styled.div`
  ${({ theme }) => theme.fonts.headline4};
  margin: auto;
  color: ${({ theme, color }) =>
    color === 'blue' ? theme.colors.white : theme.colors.black};
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline4};
  }
`
const DescriptionContainer = styled.div`
  display: flex;
  margin: auto 0;
  flex: 1;
  height: 3rem;
  background-color: ${({ theme }) => theme.colors.black};
  ${mediaQuery.mobile} {
    height: 3rem;
  }
`
const Description = styled.div`
  ${({ theme }) => theme.fonts.headline4};
  margin: auto auto auto 2rem;
  color: ${({ theme }) => theme.colors.white};
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline4_mobile};
    margin: auto 0.5rem;
    line-height: 1.5;
  }
`

const CalendarEntry = ({ entry }) => {
  return (
    <Container>
      <LeftTab color={entry.color} />
      <Left />
      <DateContainer>
        <DayContainer>
          <Day>{entry.day}</Day>
        </DayContainer>
        <MonthContainer color={entry.color}>
          <Month color={entry.color}>{entry.month}</Month>
        </MonthContainer>
      </DateContainer>
      <DescriptionContainer>
        <Description>{`${entry.type}: ${entry.description}`}</Description>
      </DescriptionContainer>
    </Container>
  )
}

CalendarEntry.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(CalendarEntry)
