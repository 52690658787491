import PropTypes from 'prop-types'
import React, { useState } from 'react'
import axios from 'axios'

import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { withTheme } from 'emotion-theming'

import {
  Container as Containr,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  margin-bottom: 2.5rem;
  ${mediaQuery.mobile} {
    margin-bottom: 1rem;
  }
`

const SpacerBlack = styled.div`
  flex: 1;
  height: 9.375rem;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 2;
  ${mediaQuery.tabletLandscape} {
    display: none;
  }
  ${mediaQuery.tablet} {
    display: none;
  }
  ${mediaQuery.mobile} {
    display: none;
  }
`
const CenterContainer = styled(Center)`
  position: relative;
  align-items: center;
  height: 9.375rem;
  background-color: ${({ theme }) => theme.colors.black};
  justify-content: center;
  z-index: 2;
  ${mediaQuery.tabletLandscape} {
    border-left: 5vw solid ${({ theme }) => theme.colors.black};
  }
  ${mediaQuery.tablet} {
    border-left: 5vw solid ${({ theme }) => theme.colors.black};
  }
  ${mediaQuery.mobile} {
    height: auto;
    width: 100vw;
    border-left: unset;
  }
`
const EmailInput = styled.input`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.white};
  width: 18.75rem;
  height: 3.125rem;
  margin-right: 1rem;
  padding: 0 1rem;
  border: none;
  ::placeholder {
    color: inherit;
  }
  &:focus::placeholder {
    color: transparent;
  }
  ${mediaQuery.mobile} {
    height: 2.5rem;
    flex: 1;
    margin: 2rem 0.5rem 2rem 1.5rem;
  }
  ${mediaQuery.helper350} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_helper350};
    height: 2.5rem;
    flex: 1;
    margin: 2rem 0.5rem 2rem 1.5rem;
  }
`
const EmailButton = styled.div`
  display: flex;
  height: 3.125rem;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  ${mediaQuery.mobile} {
    height: 2.5rem;
    margin-right: 1.5rem;
  }
`
const EmailButtonText = styled.div`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  margin: auto;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};
    padding: 0.5rem;
  }
  ${mediaQuery.helper350} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_helper350};
    padding: 0.5rem;
  }
`
const UserFeedbackMessage = styled.p`
  color: white;
  /* font-size: 0.7rem; */
  position: absolute;
  top: 40px;
  right: 450px;
  background-color: ${({ theme }) => theme.colors.red};
  padding: 0.5rem;
  font-family: ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  text-transform: uppercase;
  ${mediaQuery.tabletLandscape} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_helper350};
    padding: 0.25rem;
    right: 490px;
  }

  ${mediaQuery.tablet} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_helper350};
    padding: 0.25rem;
    right: 250px;
  }

  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_helper350};
    padding: 0.25rem;
    right: 180px;
    top: -30px;
  }
`
const validateEmail = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email.toLowerCase()))
}

//Breakup this function into smaller bits
const subscribeUsersEmail = (emailAddress, setSuccessMessage, text) => {
  if (validateEmail(emailAddress)) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + process.env.GATSBY_SENDGRID_API_KEY,
    }
    const data = {
      list_ids: [process.env.GATSBY_CINDERHOUSE_EMAIL_LIST_ID],
      contacts: [
        {
          email: `${emailAddress}`,
        },
      ],
    }

    axios
      .put('https://api.sendgrid.com/v3/marketing/contacts', data, { headers })
      .then(response => {
        setSuccessMessage(text.successMessage)
        //This is brittle. It depends on the time of the animation
        //of the component being animated in being under the time of the setTimeout
        //Write something better!
        setTimeout(() => {
          setSuccessMessage(false)
        }, 5200)
        console.log('setSuccessMessage was called')
      })
      .catch(err => {
        setSuccessMessage(text.errorMessage)
        setTimeout(() => {
          setSuccessMessage(false)
        }, 5200)
      })
  } else {
    setSuccessMessage(`"${emailAddress}" ${text.invalidMessage}`)
    return setTimeout(() => {
      setSuccessMessage(false)
    }, 5200)
  }
}

const fadeInOut = keyframes`
  0% {opacity: 0.1}
  15% {opacity: 1}
  75% {opacity: 1}
  100% {opacity: 0}
`

const Email = ({ theme, text }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [successMessage, setSuccessMessage] = useState(false)

  return (
    <Container>
      <SpacerBlack />
      <CenterContainer>
        <EmailInput
          placeholder={text.placeholder}
          type="email"
          onChange={event => setEmailAddress(event.target.value)}
        />
        <EmailButton
          onClick={() =>
            subscribeUsersEmail(emailAddress, setSuccessMessage, text)
          }
        >
          <EmailButtonText>{text.submit}</EmailButtonText>
        </EmailButton>
        {successMessage ? (
          <UserFeedbackMessage
            css={css`
              opacity: 0;
              animation: ${fadeInOut} 5s linear;
            `}
          >
            {successMessage}
          </UserFeedbackMessage>
        ) : (
          <></>
        )}
      </CenterContainer>
      <Spacer />
    </Container>
  )
}

Email.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Email)
