import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import {
  Container as Containr,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  position: relative;
  margin-bottom: 1rem;
  ${mediaQuery.mobile} {
    margin-bottom: 0.5rem;
  }
`
const SpacerYellowLine = styled.div`
  position: absolute;
  top: calc(3rem * 1.05 * 2 - 3rem * 1.05 * 0.5);
  left: 0;
  width: calc(((100vw - 37.5rem) / 2) + (37.5rem * 0.3));
  height: 2px;
  padding-right: 0.625rem;
  background-color: ${({ theme }) => theme.colors.yellow};
  ${mediaQuery.tablet} {
    width: 20vw;
    top: calc(3rem * 1.5);
  }
  ${mediaQuery.mobile} {
    width: 42.5vw;
    top: calc(1.75rem * 1.5);
  }
  ${mediaQuery.helper500} {
    top: calc(1.75rem * 1.5);
    width: 35vw;
  }
  ${mediaQuery.helper400} {
    top: calc(1.75rem * 1.5);
    width: 25vw;
  }
  ${mediaQuery.helper350} {
    top: calc(1.75rem * 1.5);
    width: 18vw;
  }
`
const CenterContainer = styled(Center)`
  justify-content: flex-end;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline2_mobile};
  }
`
const Title = styled.div`
  ${({ theme }) => theme.fonts.headline2};
  color: ${({ theme }) => theme.colors.yellow};
  width: 85%;
  margin: auto 0 0.75rem auto;
  text-align: right;
  ${mediaQuery.tabletLandscape} {
    width: 85%;
  }
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    width: 65%;
    ${({ theme }) => theme.fonts.headline2_mobile};
  }
  ${mediaQuery.helper400} {
    width: 80%;
    ${({ theme }) => theme.fonts.headline2_mobile};
  }
`
const Upcoming = ({ theme, text }) => {
  return (
    <Container id="calendar">
      <Spacer />
      <SpacerYellowLine />
      <CenterContainer>
        <Title>{text.title}</Title>
      </CenterContainer>
      <Spacer />
    </Container>
  )
}

Upcoming.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Upcoming)
