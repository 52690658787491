import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'

import { mediaQuery } from './styled'

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 1rem 0;
  justify-content: flex-start;
`
const Tag = ({ className, text }) => <div className={className}>{text}</div>

const StyledTag = styled(Tag)`
  ${({ theme }) => theme.fonts.tags};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 0.25rem;
  padding: 0 0.25rem;
  ${mediaQuery.mobile} {
    display: none;
  }
`

const Tags = ({ tags }) => {
  return (
    <TagContainer>
      {tags.split(',').map((tag, i) => (
        <StyledTag key={i} text={tag.trim()} />
      ))}
    </TagContainer>
  )
}

Tags.propTypes = {
  tags: PropTypes.string.isRequired,
}

Tag.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
}

export default Tags
