import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Tags from './Tags'
import { mediaQuery } from './styled'

const CardBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
`
const CardTitleText = styled.div`
  ${({ theme }) => theme.fonts.headline4};
  color: ${({ theme }) => theme.colors.yellow};
  padding: 1rem 1rem 0 1rem;
`
const CardBodyText = styled.div`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.125rem 1rem 0 1rem;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};
  }
`
const CardActionText = styled.a`
  ${({ theme }) => theme.fonts.cardAction};
  color: ${({ theme }) => theme.colors.yellow};
  margin: auto 1rem 1rem auto;
  cursor: pointer;
  text-decoration: none;
  ${mediaQuery.mobile} {
    font-size: 0.9rem;
  }
`

const EpisodeCard = ({ children, className, text }) => {
  const { title, body, action, tags } = text
  return (
    <div className={className}>
      {children}
      <CardBottomContainer>
        <CardTitleText>{title}</CardTitleText>
        <CardBodyText>{body}</CardBodyText>
        <CardActionText
          href={'https://www.patreon.com/user?u=32661820&fan_landing=true'}
        >
          {action}
        </CardActionText>
      </CardBottomContainer>
      {/* <Tags tags={tags}></Tags> */}
    </div>
  )
}

EpisodeCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  text: PropTypes.object.isRequired,
}

export default EpisodeCard
