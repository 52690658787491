import PropTypes from 'prop-types'
import React from 'react'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import {
  Container as Containr,
  CenterContainer,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  background-color: ${({ theme }) => theme.colors.black};
`
const Text = styled.div`
  ${({ theme }) => theme.fonts.mainBodyText};
  color: ${({ theme }) => theme.colors.white};
  margin: 1.5rem 0 1.5rem auto;
  ${mediaQuery.mobile} {
    text-align: right;
    margin: 1rem 0 1rem 7rem;
  }
  ${mediaQuery.helper400} {
    text-align: right;
    margin: 1rem 0 1rem 4rem;
  }
`
const Footer = ({ theme, text }) => {
  return (
    <Container>
      <Spacer />
      <CenterContainer>
        <Text>{text.text}</Text>
      </CenterContainer>
      <Spacer />
    </Container>
  )
}

Footer.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Footer)
