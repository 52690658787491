import PropTypes from 'prop-types'
import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import SuggestedModule from './SuggestedModule'

import {
  Container as Containr,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  flex: 1;
  margin-bottom: 2.5rem;
  position: relative;
  z-index: 1;
  ${mediaQuery.mobile} {
    width: 100vw;
    margin-bottom: 1rem;
  }
`
const CenterContainer = styled(Center)`
  ${mediaQuery.mobile} {
    width: 100vw;
  }
`
const GreyOutlineBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: calc(100% / 2 + (9.375rem / 2) + 2.5rem);
  top: calc((9.375rem / 2 + 2.5rem) * -1);
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: -1;
`
const TitleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  position: relative;
  ${mediaQuery.mobile} {
    width: 100vw;
    margin: 0 0 1rem 1rem;
  }
`
const Title = styled.div`
  z-index: 1;
  ${({ theme }) => theme.fonts.headline2};
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 0.75rem;
  width: 23rem;
  position: relative;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline2_mobile};
    width: auto;
    margin: 0;
  }
`
const SpacerBlueLineContainer = styled.div`
  flex: 1;
  position: relative;
`
const SpacerBlueLine = styled.div`
  position: absolute;
  top: calc(3rem * 1.05 / 2);
  left: 0;
  width: calc(((100vw - 37.5rem) / 2) + (37.5rem - 23rem));
  height: 2px;
  background-color: ${({ theme }) => theme.colors.blue};
  ${mediaQuery.tabletLandscape} {
    width: calc(100% + 5vw);
  }
  ${mediaQuery.tablet} {
    width: calc(100% + 5vw);
  }
  ${mediaQuery.mobile} {
    top: calc(1.75rem / 2);
    left: 0.5rem;
    width: calc(100% - 0.5rem);
  }
`
const Description = styled.div`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  color: ${({ theme }) => theme.colors.black};
  margin: 1rem 0 0 auto;
  text-align: right;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};
    margin: 1rem 1rem 0 1rem;
  }
`

const DivTest = styled.div`
  position: relative;
  border: 2px solid blue;
`

const Suggested = ({ theme, text }) => {
  const airtableArticles = useStaticQuery(graphql`
    query ArticleQuery {
      articles: allAirtable(
        filter: { table: { eq: "articles" }, data: { active: { eq: true } } }
        sort: { fields: data___order, order: ASC }
      ) {
        nodes {
          data {
            title
            link
            image
          }
          recordId
        }
      }
    }
  `).articles.nodes

  return (
    <Container>
      <Spacer />
      <CenterContainer>
        <GreyOutlineBackground />
        <TitleRow>
          <Title>{text.title}</Title>
          <SpacerBlueLineContainer>
            <SpacerBlueLine />
          </SpacerBlueLineContainer>
        </TitleRow>
        <SuggestedModule items={airtableArticles} text={text} />
        <Description>{text.body}</Description>
      </CenterContainer>
      <Spacer />
    </Container>
  )
}

Suggested.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Suggested)
