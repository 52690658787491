import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { mediaQuery } from './styled'

import Hubspot from '../../../static/Hubspot.png'
import Outlook from '../../../static/Outlook.png'
import PinterestDesignSystem from '../../../static/PinterestDesignSystem.png'

const CodeContainerTry = styled.div`
  position: relative;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 800px;
  margin-top: 4rem;
  ${mediaQuery.helper850} {
    height: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
`
const CodeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  height: 66.66vh;
  z-index: 500;
  ${mediaQuery.helper850} {
    height: 100%;
    flex-direction: column;
  }
`
const BoxSplitter = styled.div`
  width: 33.333%;
  height: 100%;
  z-index: 1;
  ${mediaQuery.helper850} {
    height: auto;
    width: 100%;
    h3 {
      font-size: 1.4rem;
      line-height: 1;
    }
    a {
      font-size: 0.9rem;
    }
  }
  .card0 {
    height: 270px;
    top: 0px;
    div {
      background-color: ${({ theme }) => theme.colors.greyDarker};
    }
    ${mediaQuery.helper850} {
      height: 170px;
      right: -25px;
    }
  }
  .card1 {
    height: 410px;
    top: -65px;
    div {
      background-color: ${({ theme }) => theme.colors.blue};
    }
    ${mediaQuery.helper850} {
      height: 410px;
      top: 0;
      left: -20px;
      background-color: blue;
    }
  }
  .card2 {
    height: 292px;
    top: 34px;
    div {
      background-color: ${({ theme }) => theme.colors.greyDarker};
    }
    ${mediaQuery.helper850} {
      height: 292px;
      top: 0;
      right: -30px;
    }
  }
  .card3 {
    height: 484px;
    top: 270px;
    div {
      background-color: ${({ theme }) => theme.colors.red};
    }
    ${mediaQuery.helper850} {
      height: 484px;
      top: 0;
      left: -10px;
    }
  }
  .card4 {
    height: 202px;
    top: 345px;
    div {
      background-color: ${({ theme }) => theme.colors.greyDarker};
    }
    ${mediaQuery.helper850} {
      height: 202px;
      top: 0;
      right: -15px;
    }
  }
  .card5 {
    height: 339px;
    top: 326px;
    div {
      background-color: ${({ theme }) => theme.colors.yellow};
    }
    ${mediaQuery.helper850} {
      height: 339px;
      top: 0;
      left: -30px;
    }
  }
`
const CardInnerContainer = styled.div`
  position: absolute;
  max-width: 33.3333%;
  width: 100%;
  .cardHook:nth-of-type(1) {
    color: purple !important;
  }
  ${mediaQuery.helper850} {
    max-width: 100%;
    position: relative;
  }
`

const CardTitle = styled.h3`
  color: #fff;
  position: absolute;
  padding-left: 1rem;
  max-width: 80%;
  margin-top: 1rem;
`
const CardUrl = styled.a`
  font-size: 0.7rem;
  color: #fff;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.whiteDarker};
  }
  transition: 0.2s;
  text-decoration: none;
`
const CardImg = styled.div`
  color: #fff;
  width: 100%;
  height: 100%;
`
const imageSelector = image => {
  if (image === 'Hubspot') {
    return Hubspot
  } else if (image === 'Outlook') {
    return Outlook
  } else if (image === 'PinterestDesignSystem') {
    return PinterestDesignSystem
  }
}

const SuggestedModule = ({ items, text }) => {
  console.log('I am airtable items: ', items)
  return (
    <CodeContainerTry>
      <CodeContainer>
        {items.map((item, index) => {
          const { image, title, link, recordId } = item.data
          return (
            <BoxSplitter>
              <CardInnerContainer key={recordId} className={`card${index}`}>
                <CardTitle>{title}</CardTitle>
                <CardUrl href={link}>Take Me There!</CardUrl>
                <CardImg
                  css={css`
                    background: url(${imageSelector(image)}) no-repeat center
                      center;
                    background-size: cover;
                    background-color: ${({ theme }) => theme.colors.greyDarker};
                  `}
                />
              </CardInnerContainer>
            </BoxSplitter>
          )
        })}
      </CodeContainer>
    </CodeContainerTry>
  )
}

export default SuggestedModule
