import PropTypes from 'prop-types'
import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import CalendarEntry from './CalendarEntry'

import {
  Container as Containr,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const Container = styled(Containr)`
  margin-bottom: 2.5rem;
  position: relative;
  ${mediaQuery.tabletLandscape} {
    margin-bottom: 0;
  }
  ${mediaQuery.tablet} {
    margin-bottom: 0;
  }
  ${mediaQuery.mobile} {
    margin-bottom: 0;
  }
`
const SpacerWhite = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc((100vw - 37.5rem) / 2);
  background-color: ${({ theme }) => theme.colors.white};
  ${mediaQuery.tabletLandscape} {
    width: 90vw;
    left: -10vw;
    z-index: -1;
  }
  ${mediaQuery.tablet} {
    width: 90vw;
    left: -10vw;
    z-index: -1;
  }
  ${mediaQuery.mobile} {
    width: 90vw;
    left: -10vw;
    z-index: -1;
  }
`
const CenterContainer = styled(Center)`
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  ${mediaQuery.mobile} {
    padding-top: 1rem;
    padding-bottom: 0;
  }
`
const Calendar = ({ theme }) => {
  const airtableCalendarEntries = useStaticQuery(graphql`
    query CalendarQuery {
      calendarEntries: allAirtable(
        filter: { table: { eq: "calendar" }, data: { active: { eq: true } } }
        sort: { fields: data___date, order: ASC }
      ) {
        nodes {
          data {
            month
            day
            description
            type
            color
          }
          recordId
        }
      }
    }
  `).calendarEntries.nodes

  return (
    <Container>
      <Spacer />
      <SpacerWhite />
      <CenterContainer>
        {airtableCalendarEntries.map((entry, i) => (
          <CalendarEntry entry={entry.data} key={entry.recordId} />
        ))}
      </CenterContainer>
      <Spacer />
    </Container>
  )
}

Calendar.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Calendar)
