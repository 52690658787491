import PropTypes from 'prop-types'
import React, { useState } from 'react'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import {
  Container,
  CenterContainer as Center,
  Spacer,
  mediaQuery,
} from './styled'

const CenterContainer = styled(Center)`
  margin-bottom: 2.5rem;
  ${mediaQuery.mobile} {
    margin-bottom: 1.5rem;
  }
`
const Title = styled.div`
  ${({ theme }) => theme.fonts.headline1};
  padding-right: 30%;
  margin-bottom: 1rem;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.headline1_mobile};
    padding-left: 1rem;
    padding-right: 1rem;
  }
`
const SubContainer = styled.div`
  display: flex;
  width: 23.75rem;
  margin: auto 0 auto auto;
  text-align: right;
  ${mediaQuery.mobile} {
    text-align: left;
    padding-left: 3rem;
    padding-right: 1rem;
  }
`
const Sub = styled.span`
  ${({ theme }) => theme.fonts.calloutsAndSubtitles};
  color: ${({ theme }) => theme.colors.black};
  margin: auto 0;
  ${mediaQuery.mobile} {
    ${({ theme }) => theme.fonts.calloutsAndSubtitles_mobile};
  }
`
const ButtonContainer = styled(Container)``
// const HeroButton = styled.button`
//   height: 3rem;
//   width: 15rem;
//   background-color: blue;
//   font-size: 1.2rem;
//   color: #fff;
// `
const HeroButton = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 10rem;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  ${mediaQuery.mobile} {
    margin-left: 1rem;
    height: 2.125rem;
  }
`

const Hero = ({ theme, text, modalState, modalToggler }) => {
  return (
    <Container>
      <Spacer></Spacer>
      <CenterContainer>
        <Title>{text.title}</Title>
        <SubContainer>
          <Sub>{text.text}</Sub>
        </SubContainer>
        <ButtonContainer>
          <HeroButton onClick={() => modalToggler(modalState)}>
            Get Started
          </HeroButton>
        </ButtonContainer>
      </CenterContainer>
      <Spacer></Spacer>
    </Container>
  )
}

Hero.propTypes = {
  theme: PropTypes.object,
}

Hero.defaultProps = {
  theme: {},
}

export default withTheme(Hero)
